import "./styles.css"

export const Header = () => {
    
    return(

        <div>Header</div>
    )
    

}
export default Header