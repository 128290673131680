import { useState, useEffect } from "react";
import { collection, query, getDocs, where, orderBy } from "firebase/firestore";
import { db } from "../config/firebase-config";

const useGetPesan = () => {
  const [pesan, setPesan] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getPesan = async () => {
      try {
        // 1. Dapatkan data profil dari local storage
        const userProfileJSON = localStorage.getItem("userProfiles");

        // 2. Parse data JSON profil menjadi array objek JavaScript
        const userProfileData = JSON.parse(userProfileJSON);

        // 3. Dapatkan 'school' dan 'level' dari profil pertama dalam array
        const firstUserProfile = userProfileData[0];
        const school = firstUserProfile.school;
        const level = firstUserProfile.level;
        console.log(school);
        console.log(level);

        const pesanCollection = collection(db, "messages");
        const pesanQuery = query(
          pesanCollection,
          where("school", "==", school),
          where("level", "==", level),
          orderBy("created_at", "desc")
        );

        const snapshot = await getDocs(pesanQuery);
        const pesanData = [];

        snapshot.forEach((doc) => {
          pesanData.push({ id: doc.id, ...doc.data() });
        });

        setPesan(pesanData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    getPesan();
  }, []);

  console.log("Data Pesan:", pesan);

  return { pesan, loading };
};

export default useGetPesan;
