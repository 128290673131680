import "../../css/adminlte.css";
import { useAddPesan } from "../../hooks/useAddPesan";
import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";


export const NewMessage = () => {
  const { addPesan } = useAddPesan();
  const navigate = useNavigate()

  const [body, setBody] = useState("");
  const [start_at, setStart] = useState("");
  const [title, setTitle] = useState("");
  const [class_name, setClassName] = useState("");
  const [level, setLevel] = useState("");
  const levelNumber = parseInt(level, 10);
 


  const onSubmit = (e) => {
    e.preventDefault();

    if (title && body && class_name && level) {
      addPesan({
        body,
        start_at,
        title,
        class_name,
        level: levelNumber, // Simpan level sebagai number
      });

      setBody("");
      setTitle(""); 
      navigate("/Home")
      
    }
  };

  return (
    <div>
      <form className="NewMessage" onSubmit={onSubmit} id="wrapper">
        <div className="card-tools">
          <div className="card-header">
            <h3 className="card-title">New Message</h3>
          </div>
        </div>
        <div className="card-body">
          <div className="form-group">
            <label htmlFor="title">Judul Pengumuman</label>
            <input
              type="text"
              placeholder="Isi judul pengumuman"
              value={title}
              required
              id="title"
              onChange={(e) => setTitle(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="body">Isi Pengumuman</label>
            <textarea
              id="body"
              value={body}
              required
              onChange={(e) => setBody(e.target.value)}
              className="form-control"
              rows="4"
            ></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="level">Tujuan Level</label>
            <select
              id="level"
              className="form-control custom-select"
              value={level}
              onChange={(e) => setLevel(e.target.value)}
            >
              <option value="" disabled>
                Pilih Kelas
              </option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="class_name">Nama Kelas</label>
            <select
              id="class_name"
              className="form-control custom-select"
              value={class_name}
              onChange={(e) => setClassName(e.target.value)}
            >
              <option value="" disabled>
                Nama Kelas
              </option>
              <option value="Ibnu Sabil">Ibnu Sabil</option>
              <option value="Madinah">Madinah</option>
              <option value="Mekkah">Mekkah</option>
            </select>
            <button
              type="submit"
              className="btn btn-block btn-primary"
              style={{ width: "200px", marginTop: "30px" }}
            >
              Simpan pesan
            </button>
          </div>
        </div>
      </form>
      
    </div>
  );
 
};

export default NewMessage;
