import Header from "../../components/header"

export const Profile = () => {
    
    return(

        <div className="header-all">
            <Header />
            
            Halaman profile</div>
    )
    

}
export default Profile