import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Auth } from "./pages/login"
import Home from './pages/home';
import NewMessage from './pages/newMessage';
import Profile from './pages/profile';


function App() {
  return (
    <Router>
      <Routes>
        <Route path ="/" exact element={<Auth />}/>
        <Route path ="/home" exact element={<Home />}/>
        <Route path ="/NewMessage" exact element={<NewMessage />}/>
        <Route path ="/profile" exact element={<Profile />}/>          
      </Routes>
    </Router>
  );
}

export default App;
