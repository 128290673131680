import {addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { db } from '../config/firebase-config';
import { useGetUserInfo } from './useGetUserInfo';


export const useAddPesan = () => {
    const pesanCollectionRef = collection(db, "messages");
    // 1. Dapatkan data profil dari local storage
    const userProfileJSON = localStorage.getItem("userProfiles");

    // 2. Parse data JSON profil menjadi array objek JavaScript
    const userProfileData = JSON.parse(userProfileJSON);

    // 3. Dapatkan 'school' dan 'level' dari profil pertama dalam array
    const firstUserProfile = userProfileData[0];
    const school = firstUserProfile.school;
    const level = firstUserProfile.level;
    const class_name = firstUserProfile.class_name;
    const from = firstUserProfile.name

    
   // console.log("UserID:", userID); // Log the userID to the console
    const addPesan = async ({
        body,
        start_at,
        title,
        class_name,
        level

    }) => {
        await addDoc(pesanCollectionRef,{
            school,
            level,
            class_name,
            from,
            body,
            is_read: "false",
            start_at: serverTimestamp(),
            title,
            level,
            type:"discussion",
            created_at: serverTimestamp()
        })
    };
    return {addPesan};
}

