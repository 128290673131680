import React from "react"; // Import React
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import { useGetUserInfo } from "../../hooks/useGetUserInfo";
import { signOut } from "firebase/auth";
import { auth } from "../../config/firebase-config";
import { Link } from "react-router-dom";
import useGetPesan from "../../hooks/useGetPesan";
import "../../css/adminlte.css";
import "./styles.css";
import CardList from "../../components/list";

export const Home = () => {
  useGetUserInfo();
  const { name, profilePhoto } = useGetUserInfo();
  const navigate = useNavigate();

  const { pesan, loading } = useGetPesan();

  // 1. Dapatkan data profil dari local storage
  const userProfileJSON = localStorage.getItem("userProfiles");

  // 2. Parse data JSON profil menjadi array objek JavaScript
  const userProfileData = JSON.parse(userProfileJSON);

  // 3. Dapatkan 'school' dan 'level' dari profil pertama dalam array

  const firstUserProfile = userProfileData && userProfileData[0]; // Add a condition here
  const school = firstUserProfile ? firstUserProfile.school : ""; // Add a condition here

  const signUserOut = async () => {
    try {
      await signOut(auth);
      localStorage.clear();
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  /*   useGetDiscussion() */
  /* useGetPesan(); */

  return (
    <div className="body" id="wrapper">
      <div className="navbar">
        <div className="profile-header">
          <div className="profile-data" style={{ margin: "10px" }}>
            <div className="profile-user" style={{ width: "400px" }}>
              <p style={{ fontWeight: "bold", marginBottom: "-1px" }}>{name}</p>
              <p style={{ margintop: "1px" }}> {school}</p>
            </div>
            <button
              type="button"
              className="btn btn-block btn-primary"
              style={{width: "100px"}}
              onClick={signUserOut}
            >
              Sign Out
            </button>
          </div>
        </div>
      </div>

      <div className="message-header" style={{ marginBottom: "20px" }}>
        <div className="message-title">
          <h3 style={{ fontWeight: "bold" }}>Pengumuman</h3>
        </div>
        <Link to="/newMessage">
          {" "}
          {/* Tambahkan Link ke halaman "/newMessage" */}
          <button type="button" className="btn btn-block btn-primary">
            add message
          </button>
        </Link>
      </div>

      <div className="message-box">
        {pesan.map((item, index) => (
          <CardList key={index} item={item} />
        ))}
      </div>
    </div>
  );
};

export default Home; // Pastikan Anda meng-export komponen ini jika akan menggunakannya di tempat lain.
