import React from "react";
import "./styles.css";
import information from "../../assets/information.png";

const CardList = ({ item }) => {
  return (
    <div className="pesan-item" key={item.id}>
      <ul style={{ listStyle: "none" }}>
        <li>
          <div className="post">
            <div className="message-block">
              <img
                className="discusion-icon"
                src={information}
                alt="Information Icon"
              />
              <div className="message-content">
                <span className="title">{item.title}</span>
                <div className="message-sender">
                  <span>Pengirim: {item.from}</span>
                  <span style={{ marginLeft: "30px"}}>
                    Tanggal:{" "}
                    {new Date(item.created_at.toDate()).toLocaleDateString()}
                  </span>
                </div>
                <span className="message-body">{item.body}</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default CardList;
