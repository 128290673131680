import React from "react"; // Import React
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import { auth, provider } from "../../config/firebase-config";
import { signInWithPopup } from "firebase/auth";
import { useGetProfile } from "../../hooks/useGetProfile";
import "./styles.css";
import Home from "../home";

export const Auth = () => {
  const navigate = useNavigate(); // Use useNavigate from react-router-dom
  const getProfile = useGetProfile;

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);

      const authInfo = {
        userID: result.user.uid,
        name: result.user.displayName,
        profilePhoto: result.user.photoURL,
        isAuth: true,
      };

      localStorage.setItem("auth", JSON.stringify(authInfo));

      // Use navigate to go to the ExpenseTracker component
      navigate("/Home"); // Replace with the actual route path
      getProfile();
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  }

    const registerWithGoogle = async () => {
      try {
        const result = await signInWithPopup(auth, provider);
  
        const authInfo = {
          userID: result.user.uid,
          name: result.user.displayName,
          profilePhoto: result.user.photoURL,
          isAuth: true,
        };
  
        localStorage.setItem("auth", JSON.stringify(authInfo));
  
        // Use navigate to go to the ExpenseTracker component
        navigate("/profile"); // Replace with the actual route path
        
      } catch (error) {
        console.error("Error signing in with Google:", error);
      }



  };

  return (
    <div className="login-page">
      <div className="login-box">
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <a href="../../index2.html" className="h1">
              <b>QITA </b>apps
            </a>
          </div>
          <div className="card-body">
            <p className="login-box-msg">Sarana Komunikasi Sekolah</p>

            <form action="../../index3.html" method="post">
              <div className="input-group mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="icheck-primary">
                    <input type="checkbox" id="remember" />
                    <label htmlFor="remember">Remember Me</label>
                  </div>
                </div>
                <div className="col-4">
                  <button type="submit" className="btn btn-primary btn-block">
                    Sign In
                  </button>
                </div>
              </div>
            </form>

            <div className="social-auth-links text-center mt-2 mb-3">
              <button
                onClick={signInWithGoogle}
                className="btn btn-block btn-danger"
              >
                <i className="fab fa-google-plus mr-2"></i> Masuk dengan
                Google+
              </button>
            </div>

            <p className="mb-1">
              <a href="forgot-password.html">I forgot my password</a>
            </p>
            <p className="mb-0 text-center">
              <a onClick={registerWithGoogle}>Register a new membership</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
