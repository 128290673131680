import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../config/firebase-config";
import { useGetUserInfo } from "./useGetUserInfo";


export const useGetProfile = () => {
    const profileCollectionRef = collection(db, "profiles");
    const { userID } = useGetUserInfo();
    console.log (userID)

    const getProfile = async () => {
        try {
            const queryProfile = query(profileCollectionRef, where("userID", "==", userID));
            const querySnapshot = await getDocs(queryProfile);

            const profiles = [];
            querySnapshot.forEach((doc) => {
                // Dapatkan data dari dokumen
                const data = doc.data();
                profiles.push(data);
            });

            // Simpan hasil ke local storage
            localStorage.setItem("userProfiles", JSON.stringify(profiles));
        } catch (err) {
            console.error(err);
        }
    }

    // Panggil fungsi getProfile ketika komponen diinisialisasi
    getProfile();

    return {};
};
